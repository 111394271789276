import * as React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'

const PrivacyPage = () => {
  return (
    <Layout>
      <div className="container-md">
        <h1>ThinEdgeLabs Global Privacy Policy</h1>
        <p>Effective Date: November 1st, 2022</p>
        <p>ThinEdgeLabs, LLC (“we”, “us” or “our”) values your privacy. In this Privacy Policy (“Policy”), we describe how we collect, use, and store information that we obtain about visitors to our websites at https://www.thinedgelabs.org, https://www.linxwallet.xyz  (the “Sites”). This Policy applies to the Sites, applications, products and services (collectively, “Services”) on or in which it is posted, linked, or referenced.</p>

        <p>By using the Services, you accept the terms of this Policy and our Terms of Use, and consent to our collection, use, disclosure, and retention of your information as described in this Policy.  If you have not done so already, please also review our terms of use. The terms of use contain provisions that limit our liability to you and require you to resolve any dispute with us on an individual basis and not as part of any class or representative action.</p>
        <p>IF YOU DO NOT AGREE WITH ANY PART OF THIS PRIVACY POLICY OR OUR TERMS OF USE, THEN PLEASE DO NOT USE ANY OF THE SERVICES.</p>

        <p>Please note that this Policy does not apply to information collected through third-party websites or services that you may access through the Services or that you submit to us through email, text message or other electronic message or offline.</p>

        <h3>What We Collect</h3>

        <p>We collect information about you directly from you and from third parties, as well as automatically through your use of our Sites or Services.</p>

        <h4>Information We Collect Directly From You.</h4>
        <p>You may browse certain areas of the Sites without registering with us or providing us personal information.</p>

        <p>We collect email only to notify you of updated or new versions of our Services.</p>

        <p>In addition, if you are providing personal information for third parties in connection with using our Services, you are responsible for ensuring that you have all required permissions and consents to provide such personal information to us for use in connection with the Services and that our use of such personal information to provide the Services does not violate any applicable law, rule, regulation or order.</p>

        <h4>Information We Collect Automatically.</h4>
        <p>We may automatically collect the following information about your use of our Sites or Services through cookies and other technologies: </p>
        <ul>
          <li>your domain name</li>
          <li>your browser type and operating system</li>
          <li>web pages you view</li>
          <li>links you click</li>
          <li>your IP address</li>
          <li>the length of time you visit our Sites or use our Services</li>
          <li>and the referring URL, or the webpage that led you to our Sites</li>
        </ul>
        <p>We may combine this information with other information that we have collected about you, including, where applicable, your user name, name, and other personal information.</p>

        <p>Please see the section “Our Use of Cookies and Other Tracking Mechanisms” below for more information.</p>

        <h3>Use Of Personal Information</h3>
        <h4>To provide our services</h4>

        <p>We will use your personal information in the following ways:</p>
        <ul>
          <li>To enable you to access and use the Services</li>
          <li>To provide and deliver products and services that you may request.</li>
          <li>To process and complete transactions, and send you related information, including purchase confirmations and invoices</li>
          <li>To send information, including confirmations, technical notices, updates, security alerts, and support and administrative messages.</li>
        </ul>

        <h4>To comply with law</h4>
        <p>We use your personal information as we believe necessary or appropriate to comply with applicable laws (including anti-money laundering (AML) laws and know-your-customer (KYC) requirements), lawful requests and legal process, such as to respond to subpoenas or requests from government authorities.</p>

        <h4>To communicate with you</h4>
        <p>We use your personal information to communicate about promotions, upcoming events, and other news about products and services offered by us and our selected partners.</p>

        <h4>To optimize our platform</h4>
        <p>In order to optimize your user experience, we may use your personal information to operate, maintain, and improve our Services. We may also use your information to respond to your comments and questions regarding the Services, and to provide you and other users with general customer service.</p>

        <h4>With your consent</h4>
        <p>We may use or share your personal information with your consent, such as when you consent to let us post your testimonials or endorsements on our Sites, you instruct us to take a specific action with respect to your personal information, or you opt into third party marketing communications.</p>

        <h4>For compliance, fraud prevention, and safety</h4>
        <p>We may use your personal information to protect, investigate, and deter against fraudulent, unauthorized, or illegal activity.</p>


        <h3>How We Share Your Information</h3>
        <p>We may share your information, including personal information, as follows:</p>

        <h4>Consent.</h4>
        <p>Where you have provided consent, we share your information, including personal information, as described at the time of consent.</p>

        <h4>Affiliates.</h4>
        <p>We may disclose the information we collect from you to our affiliates or subsidiaries solely for the purpose of providing Services to you; however, if we do so, their use and disclosure of your personally identifiable information will be maintained by such affiliates and subsidiaries in accordance with this Policy.</p>

        <h4>Service Providers.</h4>
        <p>We may disclose the information we collect from you to third party vendors, service providers, contractors or agents who perform functions on our behalf.</p>

        <h4>Business Transfers.</h4>
        <p>If we are acquired by or merged with another company, if substantially all of our assets are transferred to another company, or as part of a bankruptcy proceeding, or are in negotiations for any of these types of transactions, we may transfer the information we have collected from you to the other company.</p>

        <h4>In Response to Legal Process.</h4>
        <p>We also may disclose the information we collect from you in order to comply with the law, a judicial proceeding, court order, or other legal process, such as in response to a subpoena.</p>

        <h4>To Protect Us and Others.</h4>
        <p>We also may disclose the information we collect from you where we believe it is necessary to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the safety of any person, violations of any applicable Terms of Use or this Policy, or as evidence in litigation in which we are involved.</p>

        <h4>Aggregate and De-Identified Information.</h4>
        <p>We may share aggregate or de-identified information about users and their use of the Services with third parties and publicly for marketing, advertising, research or similar purposes.</p>

        <p>Please note that except as noted above, we will not sell or share your personal information with any third party for their direct marketing purposes without your consent.</p>

        <h3>Our Use of Cookies and Other Tracking Mechanisms</h3>
        <p>We and our service providers use cookies and other tracking mechanisms to track information about your use of our Sites and Services. We may combine this information with other personal information we collect from you (and our third party service providers may do so on our behalf).</p>

        <p>Currently, our systems do not recognize browser “do-not-track” requests. You may, however, disable certain tracking as discussed in this section (e.g., by disabling cookies), but such disabling will impair use of the Sites and Services.</p>

        <h4>Cookies</h4>
        <p>Cookies are alphanumeric identifiers that we transfer to your computer’s hard drive through your web browser for record-keeping purposes. Some cookies allow us to make it easier for you to navigate our Sites and Services, while others are used to enable a faster log-in process or to allow us to track your activities at our Sites and Services. There are two types of cookies: session and persistent cookies.</p>

        <h4>Session Cookies.</h4>
        <p>Session cookies exist only during an online session. They disappear from your computer when you close your browser or turn off your computer.</p>

        <h4>Persistent Cookies.</h4>
        <p>Persistent cookies remain on your computer after you have closed your browser or turned off your computer. We use persistent cookies to track aggregate and statistical information about user activity.</p>

        <h3>Disabling Cookies</h3>
        <p>Most web browsers automatically accept cookies, but if you prefer, you can edit your browser options to block them in the future. The Help portion of the toolbar on most browsers will tell you how to prevent your computer from accepting new cookies, how to have the browser notify you when you receive a new cookie, or how to disable cookies altogether. Visitors to our Sites who disable cookies will not be able to browse certain areas of the Sites or use the Services.</p>

        <h3>Third Party Analytics.</h3>
        <p>We use automated devices and applications, such as Google Analytics, to evaluate usage of our Sites and our Services. We also may use other analytic means to evaluate our Services. We use these tools to help us improve our Services, performance and user experiences. These entities may use cookies and other tracking technologies to perform their services. We do not share your personal information with these third parties.</p>

        <h3>Third-Party Links</h3>
        <p>Our Sites and Services may contain links to third-party websites. Any access to and use of such linked websites is not governed by this Policy, but instead is governed by the privacy policies of those third party websites. We are not responsible for the information practices of such third party websites.</p>

        <h3>Security of My Personal Information</h3>
        <p>We have implemented commercially reasonable precautions to protect the information we collect from loss, misuse, and unauthorized access, disclosure, alteration, and destruction. Please be aware that despite our efforts, no data security measures can guarantee 100% security.
        You should take steps to protect against unauthorized access to your password, phone, and computer. We are not responsible for any lost, stolen, or compromised passwords or for any activity on your account via unauthorized password activity.</p>

        <h3>Contact Information</h3>
        <p>We welcome your comments or questions about this Policy, and you may contact us at: privacy@thinedgelabs.org.</p>

        <h3>Changes to this Privacy Policy</h3>
        <p>We may change this privacy policy at any time. We encourage you to periodically review this page for the latest information on our privacy practices. If we make any changes, we will change the ‘Effective Date’ above.</p>

        <p>Any modifications to this Privacy Policy will be effective upon our posting of the new terms and/or upon implementation of the changes to the Sites (or as otherwise indicated at the time of posting). In all cases, your continued use of the Sites or Services after the posting of any modified Privacy Policy indicates your acceptance of the terms of the modified Privacy Policy.</p>

        <h3>Eligibility</h3>
        <p>If you are under the age of majority in your jurisdiction of residence, you may use the Services only with the consent of or under the supervision of your parent or legal guardian.</p>

        Consistent with the requirements of the Children’s Online Privacy Protection Act (COPPA), if we learn that we have received any information directly from a child under age 13 without first receiving his or her parent’s verified consent, we will use that information only to respond directly to that child (or his or her parent or legal guardian) to inform the child that he or she cannot use the Sites and subsequently we will delete that information.

        <h3>Location of Information</h3>
        <p>Our Sites and Services are offered from the United States. We store any information we collect in the United States. If you access the Services or Sites from outside the United States, you agree to the transfer of your information to the United States, which may have less protections for your personal information than your jurisdiction of residence.</p>

        <h3>NOTICE TO CALIFORNIA RESIDENTS</h3>

        <p>Under California Civil Code Section 1789.3, California users are entitled to the following consumer rights notice: California residents may reach the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs by mail at 1625 North Market Blvd., Sacramento, CA 95834, or by telephone at (916) 445-1254 or (800) 952-5210.</p>

        <p>This section provides additional details about the personal information we collect about California consumers and the rights afforded to them under the California Consumer Privacy Act or “CCPA.”
        For more details about the personal information we collect from you, please see the “What We Collect” section above. We collect this information for the business and commercial purposes described in the “Use of Personal Information” section above. We share this information with the categories of third parties described in the “How We Share Your Information” section above. Company does not sell (as such term is defined in the CCPA) the personal information we collect (and will not sell it without providing a right to opt out). Please refer to our “Our use of Cookies and Other Tracking Mechanisms” section above for more information regarding the types of third-party cookies, if any, that we use.</p>

        <p>Subject to certain limitations, the CCPA provides California consumers the right to request to know more details about the categories or specific pieces of personal information we collect (including how we use and disclose this information), to delete their personal information, to opt out of any “sales” that may be occurring, and to not be discriminated against for exercising these rights.</p>

        <p>California consumers may make a request pursuant to their rights under the CCPA by contacting us at legal@thinedgelabs.org. Please note that you must verify your identity and request before further action is taken. As a part of this process, government identification may be required. Consistent with California law, you may designate an authorized agent to make a request on your behalf. In order to designate an authorized agent to make a request on your behalf, you must provide a valid power of attorney, the requester’s valid government issued identification, and the authorized agent’s valid government issued identification.</p>

      </div>
    </Layout>
  )
}


export const Head = ({ location }) => <Seo title="Privacy" locationPath={location.pathname} />


export default PrivacyPage